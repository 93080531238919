.header {
  height: 8vh;
  position: fixed;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15%;
  justify-content: center;
}
.header > div {
  margin-left: auto;
  height: 10px;
}
.header_nav {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-left: auto;
  color: black;
  font-size: 13px;
  font-weight: 500;
}
.header_nav_list {
  margin: 0 20px;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.header_nav_list:hover {
  color: white;
}
.dropdown {
  display: flex;
  flex-direction: column;
}
.dropdown_div_first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropdown_div_first-child:hover {
  color: white;
}
.header_arrow {
  font-size: 15px;
  margin-left: -10px;
  cursor: pointer;
}
.header_dropdown_div {
  display: block;
  background: #000;
  padding: 10% 20%;
  width: max-content;
  border-radius: 2.5px;
  margin-top: 10px;
}
.header_dropdown_div_false {
  display: none;
}
.header_dropdown_div > h1 {
  font-weight: 500;
  font-size: 15px;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}
.header_dropdown_div > h1:hover {
  color: sienna;
}
.home_menu {
  display: none;
  font-size: 30px;
  margin-left: auto;
  z-index: 25;
}
.home_sidebar {
  position: absolute;
  z-index: 100;
  background-color: black;
  color: white;
  top: 0;
  left: -100%;
  width: 95%;
  margin: 0;
  min-height: 100vh;
  transition: all 0.75s linear;
}
.home_sidebar_true {
  position: absolute;
  z-index: 100;
  background-color: black;
  color: white;
  top: 0;
  left: 0;
  width: 95%;
  margin: 0;
  min-height: 100vh;
  transition: all 0.75s linear;
}
.home_sidebar_close {
  font-size: 30px;
  color: white;
  margin: 2.5%;
  margin-left: auto;
}
.home_sidebar_navbar {
  list-style-type: none;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.home_sidebar_navbar > li {
  margin: 7% 0;
  width: 100%;
  padding: 0 10px;
}
.home_sidebar_link {
  text-decoration: none;
  font-size: 18px;
  color: white;
}
.evajay_header {
  object-fit: contain;
  color: black;
  font-size: 40px;
}
.evajay_header2 {
  object-fit: contain;
  color: black;
  font-size: 40px;
}

/* @media (orientation: landscape) {
  .home_sidebar {
    position: absolute;
    z-index: 100;
    background-color: #28282b;
    color: white;
    top: 0;
    left: -100%;
    width: 85%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
  }
  .home_sidebar_true {
    position: absolute;
    z-index: 100;
    background-color: #28282b;
    color: white;
    top: 0;
    left: 0;
    width: 85%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
  }
  .home_sidebar_close {
    font-size: 30px;
    color: white;
    margin: 2.5%;
  }
  .home_sidebar_navbar {
    list-style-type: none;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .home_sidebar_navbar > li {
    margin: 2% 0;
    width: 100%;
    padding: 0 10px;
  }
  .home_sidebar_link {
    text-decoration: none;
    font-size: 18px;
    color: white;
  }
} */

@media all and (max-width: 1600px) {
  .header_nav {
    display: none;
    width: 100%;
  }
  .header > button {
    display: none;
  }
  .header_prime {
    font-size: 22px;
  }
  .header {
    display: flex;
    justify-content: start;
    padding: 0 2%;
    min-height: 8vh;
    opacity: 1;
  }
  .header.transparent {
    padding: 0 2%;
    opacity: 1;
  }
  .home_menu {
    display: block;
    color: black;
  }
  .evajay_header {
    margin: 10px;
    font-size: 35px;
  }
  .evajay_header2 {
    color: white;
    font-size: 35px;
    margin: 10px;
  }
  .header_dropdown_div {
    display: block;
    color: white;
    padding: 0 7.5%;
    width: max-content;
    border-radius: 2.5px;
  }
  .header_dropdown_div > h1 {
    font-weight: 500;
    font-size: 15px;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;
    border-top: 2px solid black;
    padding-top: 0;
    border-left: 1px solid gray;
    padding-left: 15px;
  }
  .header_dropdown_div > h1:hover {
    color: black;
  }
}
