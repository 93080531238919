.home_section_one {
    min-height: 100vh;
    background: url(/src/assets/2.jpg);
    background-size: cover;
    padding-top: 30vh;
    overflow: hidden;
}
.section_two_bg {
    padding-top: 150px;
    width: 100%;
    background: transparent;
    border-radius: 0;
    overflow: hidden;
    
}
.section_two_fg {
    background: transparent;
    width: 500%;
    position: relative;
    display: flex;
    animation: primary 20s linear infinite;
}
.primary_one {
    display: flex;
    width: fit-content;
    align-items: center;
    opacity: 0.3;
    color: white;
}
.primary_two {
    display: flex;
    width: fit-content;
    align-items: center;
    opacity: 0.3;
    color: white;
    font-family: 'Nothing You Could Do', cursive;
}
.primary_one > h1 {
    font-size: 25px;
    margin: 0 10px;
    font-weight: 500;
    font-family: 'Nothing You Could Do', cursive;
}
.primary_two > h1 {
    font-size: 25px;
    margin: 0 10px;
    font-weight: 500;
}
.home_section_one_hero {
    margin: 0 15%;
    padding: 30px 20px;
    color: white;
    /* margin-top: 30vh; */
    background: transparent;
}
.home_section_one_hero h1{
    font-size: 85px;
    font-weight: 400;
    color: burlywood;
    width: 60%;
    font-family: primarySerif, serif;
}
.home_section_one_hero p{
    font-size: 18px;
    line-height: 35px;
    width: 40%;
    color: white;
}
.section_one_arrow {
    position: absolute;
    top: 65vh;
    width: 10%;
    color: white;
    left: 80%;
    z-index: 2;
}
.arrow_one {
    width: 100%;
    font-size: 5px;
    opacity: 0;
    animation: arrowone 3s linear infinite;
    animation-delay: 0;
    animation-direction: alternate;
}
.arrow_two {
    width: 100%;
    font-size: 15px;
    opacity: 0;
    animation: arrowtwo 3s linear infinite;
    animation-delay: 1s;
    animation-direction: alternate;

}
.arrow_three {
    width: 100%;
    font-size: 25px;
    opacity: 0; 
    animation-delay: 6s;
    animation: arrowthree 3s linear infinite;
    animation-delay: 2s;
    animation-direction: alternate;

}
.home_section_two {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 3% 15%;
}
.home_section_two > h1{
    color: white;
    align-items: center;
    font-size: 40px;
    font-weight: 400;
    font-family: primarySerif, serif;
}
.home_section_two > p{
    color: sienna;
    align-items: center;
    font-size: 17px;
    margin-bottom: 20px;
}
.home_section_two_div {
    background: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
.home_section_two_div > div{
    width: 24%;
    border: 1px solid gray;
    border-left: 4px solid gray;
    padding: 10px 50px;
    transition: 1s linear;
    cursor: pointer;
} 
.home_section_two_div > div:hover{
    border-left: 4px solid sienna;
} 
.home_section_two_div > div > h1{
    color: white;
    font-size: 18px;
    font-weight: 100;
    margin: 10px 0;
} 
.home_section_two_div > div  > h3{
    color: sienna;
    font-size: 10px;
    font-weight: 400;
    margin: 10px 0;
} 
@keyframes primary {
    0% { left: 0; }
    100% {left: -100%;}
}
@keyframes arrowone {
    0% { opacity: 0; }
    100% {opacity: 1;}
}
@keyframes arrowtwo {
    0% { opacity: 0; }
    100% {opacity: 1;}
}
@keyframes arrowthree {
    0% { opacity: 0; }
    100% {opacity: 1;}
}
.home__section__xx {
    background: #111214;
    width: fit-content;
}
.wrapper {
    display: flex;
    align-items: center;
}
.wrapper .static-text{
    font-size: 20px;
    color: white;
}
.wrapper .dynamic-text{
    font-size: 20px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
}
.dynamic-text li {
    list-style-type: none;
    color: sienna;
    font-weight: 600;
    animation: slide 16s steps(4) infinite;
    position: relative;
    top: 0;
}
@keyframes slide {
    100% {
        top: -120px
    }
}
.dynamic-text li span {
    position: relative;
}
.dynamic-text li span::after {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    border-left: 2px solid sienna;
    padding: 2px 0;
    background: #111214;
    animation: typing 4s steps(21) infinite;
}

@keyframes typing {
    50% {
        left: 100%;
        margin: 0 -5px 0 5px;
    }
    100% {
        left: 0%;
        margin: 0 -5px 0 5px;
    }
}
@media all and (max-width: 1250px) {
    
}
@media all and (max-width: 600px) {
    .section_two_bg {
    padding-top: 100px;
    
}
.home__section__xx {
    width: 100%;
    padding: 10px 20px;
    background: #d7d2cb;
    border-color: 2px solid #d7d2cb;
}
.wrapper .static-text{
    color: black;
}
.dynamic-text li {
    color: sienna;
}
.dynamic-text li span::after {
    border-left: 2px solid sienna;
    background: #d7d2cb;
}
    .home_section_one {
        background: url(/src/assets/4.jpg);
        min-height: 100vh;
        background-size: cover;
        padding-top: 20vh;
    }
    .primary_one > h1 {
        font-size: 20px;
    }
    .primary_two > h1 {
        font-size: 20px;
    }
    .section_two_fg {
        width: 5000%;
        animation: primary 5s linear infinite;
    }
    .home_section_one_hero {
        margin: 0 5%;
        color: white;
    border: 1.5px solid #d7d2cb;

    }
    .home_section_one_hero h1{
        font-size: 60px;
        width: 80%;
        /* color: sienna; */
        margin-bottom: 15px;
        font-weight: 400;
    }
    .home_section_one_hero p{
        font-size: 15px;
        width: 90%;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .section_one_arrow {
        top: 80vh;
        left: 45%;
        display: none;
    }
    .home_section_two {
        padding: 10% 0;
        padding-bottom: 0;
        justify-content: start;
        align-items: start;
    }
    .home_section_two > h1{
        color: white;
        align-items: center;
        font-size: 35px;
        text-align: start;
        padding-left: 5%;
    }
    .home_section_two_div{
        flex-direction: column;
        margin: 10% 2.5%;
        margin-bottom: 0;
        width: 95%;
    } 
    .home_section_two > p{
        color: sienna;
        font-size: 17px;
        text-align: start;
        padding-left: 5%;
    }
    .home_section_two_div > div{
        width: 95%;
        padding: 5px 30px;
        margin-bottom: 20px;
        height: auto;
    } 
    .home_section_two_div > div > h1{
        font-size: 16px;
    }
}
