.footer {
    background: black;
}
.footer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}
.footer > div p {
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin: 0 10px;
}
.footer > div a {
    color: white;
}
.footer > div > a:visited {
    text-decoration: none;
}
.footer > div > a:active {
    text-decoration: none;
}
.footer > div > a:link {
    text-decoration: none;
}
.footer > div > h3 {
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin: 0 10px;
}
.footer > div p:hover {
    color: sienna;
    cursor: pointer;
}
.footer > section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.footer > section > p {
    color: gray;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
}
@media all and (max-width: 600px) {
    .footer > div > p {
        color: white;
        font-size: 10px;
        font-weight: 500;
        margin: 0 4px;
    }
}