.developer_body {
}
.developer_section_one {
    width: 100%;
    min-height: 70vh;
    background: #d7d2cb;
    background-size: cover;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.developer_section_one > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.developer_section_one > div > div {
    display: flex;
    flex-direction: column;
    align-items: start;
}
.developer_section_one > div > div > h3{
    color: #7a7d54;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Cormorant Garamond', serif;
}
.developer_section_one > div > div > h1{
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Cormorant Garamond', serif;
}
.developer_section_three {
    
}
.developer_section_four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5% 18%;
    background: black;
    font-family: 'Cormorant Garamond', serif;
}
.developer_section_four > p{
    font-size: 25px;
    width: 90%;
    line-height: 30px;
    text-align: center;
    color: white;
    color: gray;
}
.developer_section_four > p:last-of-type{
    margin-top: 20px;
    margin-bottom: 0;
    display: none;
}
.developer_section_five {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5% 20%;
    background: black;
    color: white;
}
.developer_section_five > h1{
    font-size: 20px;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
}
.developer_section_five > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.developer_section_five_image {
    width: 32%;
    height: 300px;
    margin: 10px 0;
    border-radius: 5px;
}
.developer_section_six {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5% 18%;
    background: black;
    color: white;
}
.developer_section_six > h1{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    color: white;
}
.developer_section_six > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
}
.developer_section_six > div > div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.developer_section_six_image {
    height: 40px;
    margin: 40px 30px;
    
}
@media all and (max-width: 600px) {
    .developer_section_one {
        justify-content: start;
        align-items: center;
        padding: 0 5%;
    }
    .developer_section_one > div{
        display: flex;
        align-items: start;
    }
    .developer_section_one > div > div h3{
        font-size: 15px;
    }
    .developer_section_one >div > div h1{
        font-size: 40px;
        font-weight: 500;
    }
    .developer_section_one p{
        font-size: 13px;
        width: 100%;
        text-align: start;
        line-height: 25px;
    }
    .developer_section_one p:last-of-type{
        display: none;
    }
    .developer_section_two{
        padding: 7.5% 5%;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
    .developer_section_two h1{
        font-size: 50px;
        font-weight: 200;
        margin: 0;
    }
    .developer_section_five {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 5% 5%;
    }
    .developer_section_five > h1{
        font-size: 20px;
    }
    .developer_section_five > div{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .developer_section_five_image {
        width: 100%;
        margin: 10px 0;
    }
    .developer_section_six {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 2.5% 5%;
    }
    .developer_section_six > h1{
        font-weight: 500;
        margin-bottom: 20px;
    }
    .developer_section_six > div{
        display: flex;
        justify-content: space-between;
        align-items: space-between;
        width: 100%;
    }
    .developer_section_six > div > div{
        width: 100%;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
    }
    .developer_section_six_image {
        height: auto;
        width: 40px;
        margin-right: 10px;
        margin-bottom:20px;
        filter: grayscale(0);
    }
    .developer_section_four {
        justify-content: center;
        align-items: center;
        padding: 5% 5%;
    }
    .developer_section_four > p{
        width: 100%;
        line-height: 30px;
        text-align: start;
    }
    .developer_section_four > p:last-of-type{
        margin-top: 20px;
        margin-bottom: 0;
        display: block;
    }
}