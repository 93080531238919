@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');

@font-face {
  font-family: primarySerif;
  src: url(Vogue.ttf);
}
@font-face {
  font-family: secondarySerif;
  src: url(Louis\ George\ Cafe\ Light.ttf);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}
body {
  box-sizing: border-box;
  font-family:  secondarySerif, 'Mulish', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

