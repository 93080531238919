.design {
    width: 100%;
    overflow: hidden;
    font-family: 'Libre Baskerville','Mulish';
}
.design_head {
    min-height: 70vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d7d2cb;
}
.lines {
    display: none;
    position: relative;
    left: 0;
    top: 16vh;
    width: 90%;
    height: 60vh;
    z-index: 1;
}
.lines2 {
    display: none;
    position: absolute;
    left: 0;
    top: 0vh;
    width: 90%;
    height: 70vh;
}
.design_head h1:first-child{
    color: #7a7d54;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Cormorant Garamond', serif;
}
.design_head h1:last-child{
    color: black;
    z-index: 1;
    font-size: 60px;
    font-weight: 500;
    font-family: 'Cormorant Garamond', serif;
}
.design_main {
    min-height: 30vh;
    background: black;
    padding: 5%;
}
.design_main h3 {
    color: white;
    margin: 0;
    font-size: 30px;
    text-align: center;
    font-weight: 400;
}
.design_section_one {
    min-height: 100vh;
    background: black;
}
.design_image {
    width: 100%;
    height: 500px;
}
.design_div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 3;
}
.design_div > div{
    width: 23.5%;
    margin: 30px 0;
}
.design_div > div h3{
    color: white;
    font-weight: 500;
    font-size: 14px;
}
.design_div > div p{
    color: gray;
    font-weight: 400;
    font-size: 12px;
}
@media all and (max-width: 1250px) {
    .lines {
        position: relative;
        left: 0;
        top: 16vh;
        width: 100%;
        height: 60vh;
        z-index: 1;
    }
    .lines2 {
        position: absolute;
        left: 0;
        top: 5vh;
        width: 100%;
        height: 70vh;
    }
    .design_head {
        min-height: 70vh;
        overflow: hidden;
        justify-content: start;
        align-items: center;
        padding: 0 5%;
    }
    .design_head h1:first-child{
        font-size: 15px;
    }
    .design_head h1:last-child{
        font-size: 40px;
    }
    .design_main h3 {
        font-size: 20px;
        text-align: start;
    }
    .design_div > div{
        width: 95%;
        margin: 30px 0;
    }
    .design_div > div h3{
        color: white;
        font-weight: 500;
        font-size: 14px;
    }
    .design_div > div p{
        color: gray;
        font-weight: 400;
        font-size: 12px;
    }
    .design_image {
        width: 100%;
        height: 400px;
    }
    
}