.writer {
    font-family: 'Cormorant Garamond', serif;
}
.writer_body {
    
}
.writer_section_one {
    min-height: 200vh;
    background: white;
    transition: 0.5s linear;
}
.writer_section_one.visible-bg{
    background: black;
    color: white;
}
.writer_section_one_div1 {
    min-height: 80vh;
    padding: 0 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.writer_section_one_div1  h1{
    font-weight: 500;
}
.writer_section_one_div1  p{
    color: burlywood;
    margin-top: 10px;
}
.writer_section_one_div1 > div{
    position: absolute;
    top: 80vh;
}
.writer_section_one_div1 > div > h4{
    font-size: 17px;
    font-weight: 500;
}
.writer_section_one_div1 > div > h3{
    color: sienna;
    font-size: 40px;
    font-weight: 500;
}
.writer_section_one_div2 {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5% 15%;
    overflow: hidden;
}
.writer_section_one_div2 > div {
    width: 30%;
    min-height: min-content;
    margin: 20px;
    overflow: hidden;
}
.writer_section_one_div2 > div > h3{
    font-weight: 500;
    margin: 10px 0;
}
.writer_image {
    width: 100%;
}
.writer_section_two {
    background: #e6e9e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}
.writer_section_two > h1 {
    font-size: 25px;
    font-weight: 600;
    color: burlywood;
    margin-bottom: 30px;
}
.writer_section_two > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15%;
}
.writer_section_two_div {
    display: flex;
    flex-direction: column;
    width: 28%;
    justify-content: center;
    align-items: center;
}
.writer_section_two_div > p{
    text-align: center;
    line-height: 25px;
    font-size: 13px;
    font-family: 'Mulish';
}
.writer_section_two_div > h3{
    font-size: 15px;
    font-weight: 600;
}
.writer_section_two_dash {
    font-size: 20px;
}

@media all and (max-width: 600px) {
    .writer_section_one_div1 > div{
        top: 70vh;
        font-size: 30px;
        font-weight: 500;
    }
    .writer_section_one_div2 {
        padding: 5% 2.5%;
    }
    .writer_section_one_div2 > div {
        width: 45%;
        min-height: auto;
        margin: 2.5%;
    }
    .writer_section_two {
        margin-top: -10vh;
    }
    .writer_section_two > h3{
        font-size: 11.5px;
        margin: 0;
        margin-top: 30px;
        color: lemonchiffon;
        letter-spacing: 5px;
        text-align: center;
    }
    .writer_section_two > h1 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .writer_section_two > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2.5%;
    }
    .writer_section_two_div {
        width: 100%;
        margin: 20px 0;
    }
    .writer_section_two_div > h3{
        font-size: 14px;
        font-weight: 500;
    }
}
